export default {
    prefix: 'custom',
    iconName: 'neck',
    icon: [
        59.582,
        37.917,
        [],
        null,
        'M44.664 0a1.354 1.354 0 00-1.33 1.355c0 9.914-7.279 17.6-13.541 17.6-6.262 0-13.543-7.69-13.543-17.6A1.354 1.354 0 0014.871.002a1.354 1.354 0 00-1.328 1.353 22.063 22.063 0 007.828 16.92l-1.324 3.979a4.107 4.107 0 01-3.01 2.748L6.39 26.594a6.4 6.4 0 00-4.819 3.863l-1.525 5.76a1.354 1.354 0 00.963 1.656 1.37 1.37 0 00.347.043 1.355 1.355 0 001.31-1.008l1.47-5.6a3.646 3.646 0 012.719-2.054l10.646-1.58a6.886 6.886 0 005.088-4.49l1.105-3.317a12.369 12.369 0 006.1 1.801 12.37 12.37 0 006.1-1.8l1.132 3.39a6.873 6.873 0 005.125 4.428l10.518 1.562a3.708 3.708 0 012.73 1.9l1.526 5.76a1.355 1.355 0 001.308 1.006 1.37 1.37 0 00.348-.043 1.354 1.354 0 00.957-1.652l-1.582-5.93a6.4 6.4 0 00-4.826-3.707l-10.52-1.564a4.086 4.086 0 01-3.048-2.692l-1.35-4.05a22.064 22.064 0 007.83-16.92A1.354 1.354 0 0044.664 0zm-24.41 31.148a1.353 1.353 0 00-.27 2.666l5.418 1.354a1.354 1.354 0 10.657-2.627l-5.418-1.353a1.353 1.353 0 00-.387-.04zm19.025 0a1.353 1.353 0 00-.334.04h-.002l-5.418 1.353a1.354 1.354 0 00.657 2.627l5.418-1.354a1.353 1.353 0 00-.32-2.666z'
    ]
};
