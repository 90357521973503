export default {
    prefix: 'custom',
    iconName: 'nose',
    icon: [
        34.903,
        63.409,
        [],
        null,
        'M21.115 0a1.116 1.116 0 00-1.078 1.088c0 .217-.508 19.804-9.72 29.816C5.234 36.418-1.078 43.311.156 48.9c.652 2.61 2.76 4.64 6.386 6.09 1.813.726 3.263 1.233 4.424 1.668 1.886.726 3.556 1.307 3.701 1.67a11.9 11.9 0 01-.943 2.828l-.291.727a1.094 1.094 0 00.58 1.449.92.92 0 00.435.074 1.334 1.334 0 001.016-.582l.289-.724c.87-2.176 1.379-3.41.871-4.57-.58-1.23-1.96-1.74-4.934-2.829-1.16-.436-2.61-.945-4.351-1.67-2.906-1.233-4.573-2.682-5.008-4.568-1.017-4.498 5.084-11.247 9.584-16.108 9.72-10.668 10.3-30.396 10.3-31.267A1.116 1.116 0 0021.124 0a1.116 1.116 0 00-.008 0zm10.229 42.537a1.1 1.1 0 00-.858 1.787c.794 1.084 2.753 4.571 2.1 6.385a3.108 3.108 0 01-1.74 2.031c-2.104.87-5.586-.58-8.633-2.031a.554.554 0 00-.363-.145 6.708 6.708 0 00-.727-.363c-3.555-1.668-6.384-2.973-7.762-1.232a2.612 2.612 0 00-.58 2.611c.435 1.016 1.669 1.741 3.555 1.96 1.523.146 3.99.147 5.367-.653 2.612 1.234 5.298 2.32 7.692 2.32a5.652 5.652 0 002.253-.436 5.154 5.154 0 002.973-3.263c1.233-3.192-1.957-7.908-2.32-8.416a1.1 1.1 0 00-.957-.555zM15.32 50.28a11.089 11.089 0 013.264 1.233 12.1 12.1 0 01-2.248-.145 3.085 3.085 0 01-1.451-.58.871.871 0 01.217-.435c0-.073.145-.073.218-.073z'
    ]
};
