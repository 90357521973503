import Swiper, { Navigation, Pagination, Lazy, Autoplay, A11y } from 'swiper';

function initSlider(sliderID) {
    const sliderLogo = new Swiper(sliderID, {
        modules: [Navigation, Pagination, Lazy, Autoplay, A11y],
        slidesPerView: 1,
        spaceBetween: 20,
        speed: 700,
        autoplay: {
            delay: 3000,
            disableOnInteraction: false
        },
        preloadImages: false,
        watchSlidesProgress: true,
        lazy: {
            checkInView: true,
            loadPrevNext: true,
            loadPrevNextAmount: 1
        },
        loop: false,
        navigation: {
            nextEl: '#slider-logo-arrow-next',
            prevEl: '#slider-logo-arrow-prev'
        },
        breakpoints: {
            320: {
                slidesPerView: 2,
                spaceBetween: 20
            },
            768: {
                slidesPerView: 3,
                spaceBetween: 60
            },
            992: {
                slidesPerView: 4,
                spaceBetween: 60
            },
            1440: {
                slidesPerView: 5,
                spaceBetween: 60
            }
        }
    });
}

if (document.getElementById('slider-logo')) {
    initSlider('#slider-logo');
}
