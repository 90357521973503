export default {
    prefix: 'custom',
    iconName: 'lower-jaw',
    icon: [
        47.977,
        43.666,
        [],
        null,
        'M29.932 0a3 3 0 00-.801.115c-2.59.74-4.171.861-4.861 2.621-.63 1.58.18 2.59 1 3.88 2 3.36 2.17 10.52-.47 14.3a2 2 0 00-2.2-1.35c-3.15.4-3.34.34-3.92.78a1.92 1.92 0 00-1-.15c-3.1.39-3.34.33-3.93.78-.72-.31-1.1-.14-4 .23a1.88 1.88 0 00-.95.4c-.73-.31-1.13-.14-4 .23a2 2 0 00-1.8 2.23l.47 3.67c-1.14 1.76.41 4.15-1.77 7.79a8.8 8.8 0 00-1.7 5.14 3 3 0 003 3h3c3.31 0 8.2-3 14.32-5 5.95-2 12-2 16.28-5.15 4.28-3.15 6.14-19.51 10.86-25.85A2.91 2.91 0 0046 3.256l-2.84-.94a3 3 0 00-3.74 1.73c-.67 1.68-.82 2.5-1.8 2.31-2.61-.52-2.84-.46-3.11-1l-1.87-3.69A3 3 0 0029.933 0zm-.018 2.057c.98-.025 1.143.954 2.756 4.18.81 1.61 2.14 1.61 4.5 2.08a3 3 0 003.38-1.82l.68-1.712a1 1 0 011.24-.568l2.85.94a.93.93 0 01.54 1.349c-4.86 6.43-6.98 22.75-10.46 25.36-3.77 2.8-9.69 2.8-15.72 4.85-5.86 2-11 5-13.68 5H3a1 1 0 01-1-1 7.18 7.18 0 011.43-4.11c2.77-4.66.57-7.939 2.41-8.229 2.77-.44 8.05-1.25 12.27-1.72 8.89-.99 11.65-8.38 10.25-17.19a12.93 12.93 0 00-2.16-5.08 1 1 0 01.58-1.471c1.664-.469 2.546-.845 3.134-.86zm-7.055 19.5L23 22.785a9.28 9.28 0 01-2.85 1.42l-.28-2.28 2.99-.368zm-5 .619l.352 2.49-3 .37c-.35-2.78-.31-2.33-.31-2.44l2.958-.42zm-5 .64l.31 2.44-3 .42c-.17-2.58-.169-2.47-.169-2.49l2.86-.37zm-5 .63l.32 2.53-2.779.43c-.26 0-.13.49-.52-2.59l2.98-.37z'
    ]
};
