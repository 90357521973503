import { CountUp } from 'countup.js';
import VisSenseFactory from 'vissense';

const counterBlocks = document.querySelectorAll('.js-counters');

if (counterBlocks.length) {
    for (let i = 0; i < counterBlocks.length; i += 1) {
        const VisSense = VisSenseFactory(window);
        const visibilityValue = 0.1;
        const countersBlockVisibility = VisSense(counterBlocks[i], {
            fullyvisible: visibilityValue
        });
        const countersBlockVisibilityMonitor = countersBlockVisibility
            .monitor({
                fullyvisible() {
                    const options = {
                        separator: ' ',
                        duration: 3
                    };
                    const counters = counterBlocks[i].querySelectorAll(
                        '.js-counter'
                    );
                    for (let j = 0; j < counters.length; j += 1) {
                        const counter = counters[j];
                        const counterNumber = counter.dataset.target;
                        const count = new CountUp(
                            counter,
                            counterNumber,
                            options
                        );
                        count.start();
                    }
                }
            })
            .start();

        // Stop monitor when element becomes visible when scrolling (after scrolling)
        countersBlockVisibilityMonitor.on('fullyvisible', () => {
            countersBlockVisibilityMonitor.stop();
        });

        // Stop monitor when element is visible after refresh (page refreshed by user)
        if (countersBlockVisibility.percentage() > visibilityValue) {
            countersBlockVisibilityMonitor.stop();
        }
    }
}
