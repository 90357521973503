export default {
    prefix: 'fak',
    iconName: 'arrow-right-2',
    icon: [
        21.713,
        12.881,
        [],
        'e001',
        'M14.313 0a.733.733 0 00-.486 1.264l5.12 4.422H.703a.733.733 0 000 1.465h18.424l-2.918 2.6-2.278 1.8a.733.733 0 10.909 1.148l2.31-1.824 4.317-3.851a.731.731 0 00-.008-1.1L14.782.154A.733.733 0 0014.313 0z'
    ]
};
