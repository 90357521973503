export default {
    prefix: 'custom',
    iconName: 'bone',
    icon: [
        55.002,
        55.032,
        [],
        null,
        'M41.176 0a6.4 6.4 0 00-4.996 10.379L10.379 36.156a6.4 6.4 0 10-2.787 11.301 6.4 6.4 0 1011.34-2.758l25.777-25.77a6.4 6.4 0 008.453-9.57l-.002.002A6.39 6.39 0 0047.48 7.6 6.4 6.4 0 0041.176 0zm.021 2.303a4.137 4.137 0 012.924 7.064 1.135 1.135 0 101.602 1.6 4.137 4.137 0 115.845 5.847h-.007a4.122 4.122 0 01-5.885-.044l-.145-.174a1.13 1.13 0 00-.798-.354 1.153 1.153 0 00-.836.332l-27.362 27.33a1.13 1.13 0 00.057 1.647l.219.174a4.137 4.137 0 11-5.848 0 1.135 1.135 0 00-1.6-1.602 4.137 4.137 0 110-5.809l.137.172a1.13 1.13 0 00.815.393 1.108 1.108 0 00.832-.328l27.4-27.36a1.13 1.13 0 00-.053-1.65l-.219-.174a4.137 4.137 0 012.922-7.064z'
    ]
};
