export default {
    prefix: 'custom',
    iconName: 'plus',
    icon: [
        35,
        35,
        [],
        null,
        'M17.512 0A1.5 1.5 0 0016 1.5V16H1.5a1.5 1.5 0 000 3H16v14.5a1.5 1.5 0 001.5 1.5 1.5 1.5 0 001.5-1.5V19h14.5a1.5 1.5 0 000-3H19V1.5A1.5 1.5 0 0017.512 0z'
    ]
};
