import throttle from 'lodash/throttle';

function calculateAvailableSideSpace(container, CssVarName) {
    const viewportWidth = document.body.clientWidth;
    const computedStyle = getComputedStyle(container);
    const sideSpace = Math.round(
        (viewportWidth -
            container.offsetWidth +
            parseFloat(computedStyle.paddingLeft) +
            parseFloat(computedStyle.paddingRight)) /
            2
    );
    document.documentElement.style.setProperty(CssVarName, `${sideSpace}px`);
}

const ccmPageContainer = document.querySelector('main > .container');

calculateAvailableSideSpace(ccmPageContainer, '--space-adjustment-from-side');
window.addEventListener(
    'resize',
    throttle(() => {
        calculateAvailableSideSpace(
            ccmPageContainer,
            '--space-adjustment-from-side'
        );
    }, 300)
);
