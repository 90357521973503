import $ from 'jquery';
import scroll from '../hash-scroll';

$('.js-gallery-collapse').on('show.bs.collapse', function() {
    const container = $(this).closest('.gallery-list-wrapper');
    const btn = container.find('.js-btn-gallery-collapse');
    btn.text('Ukryj zdjęcia');
    container.addClass('is-open');
});

$('.js-gallery-collapse').on('hide.bs.collapse', function() {
    const container = $(this).closest('.gallery-list-wrapper');
    const btn = container.find('.js-btn-gallery-collapse');
    btn.text('Więcej zdjęć');
    container.removeClass('is-open');
    scroll(container, 60);
});
