export default {
    prefix: 'custom',
    iconName: 'prosthesis',
    icon: [
        54.491,
        53.664,
        [],
        null,
        'M7.432 0C3.475 0 0 6.146 0 15.688c0 11.196 14.31 37.09 21.365 37.97l.102.006c9.806 0 19.562-7.316 21.22-15.7a12.384 12.384 0 10-12.953-12.911 10.74 10.74 0 00-8.119 8.66c-7.53-7.276-11.412-13.151-11.691-17.53l5.959-2.978a4.128 4.128 0 002.281-3.691V6.29a4.128 4.128 0 00-2.525-3.8C11.696.83 8.995 0 7.432 0zm.002 1.65c1.266 0 3.824.789 7.566 2.364A2.476 2.476 0 0116.516 6.3v1.127h-4.953a.826.826 0 000 1.65h4.953v.436a2.477 2.477 0 01-1.37 2.215l-6.43 3.214a.825.825 0 00-.456.739c0 5.252 4.51 12.018 13.47 20.418a.826.826 0 001.39-.6 9.084 9.084 0 017.567-8.955.826.826 0 00.69-.824v-.127a10.733 10.733 0 1110.732 10.732h-.111a.826.826 0 00-.824.71c-.813 5.691-5.968 11.04-12.301 13.525a7.422 7.422 0 00-2.894-5.278l13.535-15.469a4.944 4.944 0 10-1.243-1.087L24.537 44.422a7.439 7.439 0 00-9.125 2.457 64.675 64.675 0 01-4.639-6.883l3.78-3.023a.826.826 0 00-1.032-1.29l-3.585 2.868a93.364 93.364 0 01-2.346-4.406l2.814-2.112a.826.826 0 10-.992-1.32l-2.558 1.92C3.839 26.3 1.65 19.84 1.654 15.686c0-4.792.837-8.52 2.268-11.05C5.025 2.686 6.395 1.65 7.434 1.65zM42.086 22.3a3.3 3.3 0 11.021 6.6 3.3 3.3 0 01-3.3-3.3 3.3 3.3 0 013.279-3.3zm-20.62 23.108a5.78 5.78 0 015.78 5.72 19.9 19.9 0 01-5.723.886c-1.223-.181-2.978-1.518-4.994-3.832a5.777 5.777 0 014.938-2.774z'
    ]
};
