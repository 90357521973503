import $ from 'jquery';
import scroll from '../hash-scroll';

$('.js-employee-bio-collapse').on('show.bs.collapse', function() {
    const container = $(this).closest('.employee-bio');
    const btn = container.find('.js-show-employee-description');
    btn.text('Ukryj opis');
    container.addClass('is-open');
});

$('.js-employee-bio-collapse').on('hide.bs.collapse', function() {
    const container = $(this).closest('.employee-bio');
    const btn = container.find('.js-show-employee-description');
    btn.text('Pokaż pełny opis');
    container.removeClass('is-open');
    scroll(container, 30);
});
