import Swiper, { Navigation, Pagination, Lazy, Autoplay, A11y } from 'swiper';

function initSlider() {
    const methodsSlider = new Swiper('#methods-slider', {
        modules: [Navigation, Pagination, Lazy, Autoplay, A11y],
        speed: 800,
        spaceBetween: 10,
        autoplay: {
            delay: 4000
        },
        // Lazy load
        preloadImages: false,
        lazy: {
            checkInView: true,
            loadPrevNext: true,
            loadPrevNextAmount: 1
        },
        navigation: {
            nextEl: '#methods-slider-arrow-next',
            prevEl: '#methods-slider-arrow-prev'
        },
        breakpoints: {
            414: {
                slidesPerView: 2,
                spaceBetween: 10
            },
            768: {
                slidesPerView: 3,
                spaceBetween: 22
            },
            992: {
                slidesPerView: 4,
                spaceBetween: 22
            },
            1200: {
                slidesPerView: 5,
                spaceBetween: 22
            },
            1440: {
                slidesPerView: 6,
                spaceBetween: 22
            }
        }
    });
}

if (document.getElementById('methods-slider')) {
    initSlider();
}
