export default {
    prefix: 'custom',
    iconName: 'elbow',
    icon: [
        48.587,
        52.906,
        [],
        null,
        'M3.476 0a1.155 1.155 0 00-1.07.828A76.18 76.18 0 00.004 20.68c0 8.16 1.22 11.858 1.945 14.062a7.335 7.335 0 01.5 2.04 3.755 3.755 0 01-.68 1.732 5.243 5.243 0 00-.384 5.701 4.4 4.4 0 004.293 2.318 17.885 17.885 0 002.804-.293 13.574 13.574 0 011.868-.216 12.285 12.285 0 012.722 1.26 40.438 40.438 0 0020.56 5.622c8.037 0 12.874-.792 14.368-2.353a1.694 1.694 0 00.539-1.193v-8.686a1.153 1.153 0 000-.652V28.074a1.151 1.151 0 00-.633-1.03c-2.445-1.223-6.92-1.95-11.972-1.95a32.686 32.686 0 00-11.213 1.62 268.716 268.716 0 00-5.56 2.327L11.46.85A1.15 1.15 0 0010.349 0H3.515a1.155 1.155 0 00-.04 0zm.93 2.309h5.062l7.822 28.633a1.173 1.173 0 00.62.734 1.145 1.145 0 00.96.018c.004-.005 3.7-1.633 6.715-2.838a30.972 30.972 0 0110.353-1.452A31.141 31.141 0 0146.24 28.83v10.727c-9.41 3.487-17.068 2.265-22.178-3.584a1.154 1.154 0 00-1.736 1.521 18.25 18.25 0 0014.252 6.293v.002a30.811 30.811 0 009.662-1.75v7.002c-.48.356-2.837 1.557-12.605 1.557a38.38 38.38 0 01-19.473-5.344c-1.712-.924-2.85-1.537-3.81-1.537h-.004a14.647 14.647 0 00-2.225.244 16.533 16.533 0 01-2.447.266c-1.5 0-1.972-.535-2.24-1.059-.79-1.56-.35-2.328.328-3.492a5.59 5.59 0 001-2.889 8.909 8.909 0 00-.618-2.758c-.686-2.08-1.828-5.555-1.828-13.35A78.864 78.864 0 014.405 2.31z'
    ]
};
