export default {
    prefix: 'custom',
    iconName: 'phone',
    icon: [
        21.256,
        21.256,
        [],
        null,
        'M20.075 14.761a13.415 13.415 0 01-4.216-.673 1.205 1.205 0 00-1.2.283l-2.6 2.6a17.767 17.767 0 01-7.782-7.782l2.6-2.61a1.134 1.134 0 00.3-1.181 13.415 13.415 0 01-.673-4.216A1.184 1.184 0 005.314 0H1.181A1.184 1.184 0 000 1.181a20.073 20.073 0 0020.075 20.075 1.184 1.184 0 001.181-1.181v-4.133a1.184 1.184 0 00-1.181-1.181zm-1.181-4.133h2.362A10.629 10.629 0 0010.628 0v2.362a8.26 8.26 0 018.266 8.266zm-4.724 0h2.362a5.907 5.907 0 00-5.9-5.9V7.09a3.538 3.538 0 013.539 3.538z'
    ]
};
