export default {
    prefix: 'custom',
    iconName: 'star',
    icon: [
        29.006,
        26.928,
        [],
        null,
        'M14.484 0a1.049 1.049 0 00-.968.686l-2.893 8.638H1.035A1.039 1.039 0 000 10.358a.761.761 0 00.02.173 1 1 0 00.433.733l7.826 5.513-3 8.733a1.039 1.039 0 00.356 1.166 1 1 0 00.584.252 1.269 1.269 0 00.646-.235l7.639-5.443 7.637 5.443a1.213 1.213 0 00.646.235.93.93 0 00.576-.252 1.026 1.026 0 00.358-1.166l-3-8.733 7.76-5.566.189-.162a1.086 1.086 0 00.336-.693A1.1 1.1 0 0027.9 9.324h-9.523L15.484.686a1.049 1.049 0 00-1-.686z'
    ]
};
