export default {
    prefix: 'custom',
    iconName: 'email',
    icon: [
        29.783,
        21.306,
        [],
        null,
        'M0 19.419V1.887q0-.03.086-.578l9.736 8.823-9.707 9.9A2.717 2.717 0 010 19.423zM1.292.091A1.176 1.176 0 011.78 0h26.222a1.543 1.543 0 01.517.091l-9.765 8.857-1.292 1.1-2.556 2.222-2.556-2.222-1.292-1.1zm.029 21.123l9.794-9.953 3.791 3.257 3.791-3.257 9.794 9.953a1.31 1.31 0 01-.488.091H1.781a1.236 1.236 0 01-.46-.091zm18.668-11.082l9.708-8.827a1.916 1.916 0 01.086.578v17.536a2.462 2.462 0 01-.086.609z'
    ]
};
